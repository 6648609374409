<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-alert color="red lighten-1" type="error" dark v-if="loginAlert">
          {{ loginAlert }}
        </v-alert></v-col
      >
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card>
            <v-card-title class="justify-center">登录/Login</v-card-title>
            <v-card-text>
              <v-form ref="form" @submit.prevent="handleLogin" lazy-validation>
                <v-text-field
                  v-model="credentials.username"
                  :rules="[(v) => !!v || '']"
                  label="用户名/Username"
                  required
                  @change="loginAlert = null"
                ></v-text-field>
                <v-text-field
                  v-model="credentials.password"
                  type="password"
                  :rules="[(v) => !!v || '']"
                  label="密码/Password"
                  required
                  @change="loginAlert = null"
                ></v-text-field>
                <v-card-actions class="justify-center">
                  <v-btn
                    type="submit"
                    :disabled="!credentials.username || !credentials.password"
                    color="primary"
                  >
                    登录/Login
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn color="grey" text to="/password/recover">
                    忘记密码/Forgot Password ?
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import usersService from "@/services";

export default {
  data: () => ({
    credentials: {
      username: "",
      password: "",
    },
    loginAlert: null,
  }),
  created() {},
  methods: {
    handleLogin() {
      usersService
        .login(this.credentials.username, this.credentials.password)
        .then(() => {
          this.loginAlert = null;
          this.$store.commit("updateCurrentUser");
          this.$router.push("/");
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.loginAlert = "登录失败/Login failed!";
          } else {
            this.loginAlert = "未知错误/Unkown error!";
          }
        });
    },
  },
};
</script>
